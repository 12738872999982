<template>
  <main id="theme_one">
    <make-header>
      <!-- <el-select
        v-model="value"
        placeholder="请选择"
        slot="selectBox"
        class="select-box bg-white"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select> -->
      <div slot="navList">
        <div class="nav-list row">
          <router-link
            class="nav-item row-center cursor"
            to="/index"
            style="width: 200px"
          >
            <span>返回首页</span>
          </router-link>
          <div class="nav-item bg-primary white row-center">
            <span class="md">{{ procurementTitle }}</span>
          </div>
          <div
            class="nav-item row-center cursor"
            :class="{ 'primary active': index == categoryIndex }"
            v-for="(item, index) in categoryList"
            :key="index"
            @click="(categoryIndex = index), resetCategoryList()"
          >
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>
    </make-header>
    <section class="core mb20">
      <make-banner height="420" class="mb20" :id="adId"></make-banner>
      <!-- <article class="mb20">
        <ul class="floor-nav row bg-primary white nr">
          <li
            class="nav-item row-center"
            :class="{ active: curIndex == 0 }"
            @click="jumpCurIndex(0)"
          >
            外围保洁
          </li>
          <li
            class="nav-item row-center"
            :class="{ active: curIndex == 1 }"
            @click="jumpCurIndex(1)"
          >
            食堂卫生
          </li>
          <li
            class="nav-item row-center"
            :class="{ active: curIndex == 2 }"
            @click="jumpCurIndex(2)"
          >
            保洁垃圾处理
          </li>
        </ul>
      </article> -->
      <article class="row-between main-box">
        <ul class="floor-list">
          <li
            class="floor-content mb20 row-between"
            v-for="(item, index) in categoryDetailList"
            :key="index"
            ref="floor"
          >
            <div class="content-l bg-white">
              <div class="title lg normal mb10">{{ item.name }}</div>
              <div class="router-list row wrap">
                <span
                  class="nr router-item mr10 line1 cursor"
                  v-for="(items, indexs) in item.sub"
                  :key="indexs"
                  :class="indexs == item.current ? 'primary' : 'warn'"
                  @click="setCategoryCurrent(index, indexs)"
                  >{{ items.name }}</span
                >
              </div>
            </div>
            <div class="content-r">
              <div class="img-box mb10">
                <el-image
                  style="width: 100%; height: 100%"
                  :src="item.ad_image"
                  fit="cover"
                  lazy
                ></el-image>
              </div>
              <swiper :options="swiperOption" ref="mySwiper" class="swiper">
                <!-- 轮播的图片 -->
                <swiper-slide
                  class="pro-item bg-white"
                  v-for="(items, indexs) in item.goodsList"
                  :key="indexs"
                >
                  <router-link :to="`/prodetail?id=${items.id}`" class="pro-link">
                    <el-image
                      :src="items.image"
                      fit="cover"
                      class="pro-img"
                      lazy
                    ></el-image>
                    <p class="pro-title line2 xs normal mb10">
                      {{ items.name }}
                    </p>
                    <make-priceformat
                      :price="items.min_price"
                      :subscriptSize="20"
                      :firstSize="20"
                      :secondSize="20"
                    ></make-priceformat>
                  </router-link>
                </swiper-slide>
                <!-- 左右导航栏 -->
                <div class="swiper-button-prev" slot="button-prev"></div>
                <div class="swiper-button-next" slot="button-next"></div>
              </swiper>
            </div>
          </li>
        </ul>
        <div class="side-list">
          <div
            class="side-item mb20 bg-white normal"
            :class="{ fixed: showFixed }"
          >
            <div class="item-title bold lg mb20">精品推荐</div>
            <div class="pro-list">
              <router-link
                class="pro-item row"
                v-for="(item, index) in buyRecommendGoodsList"
                :key="index"
                :to="`/prodetail?id=${item.id}`"
              >
                <el-image
                  class="mr10 pro-img"
                  :src="item.image"
                  fit="cover"
                  lazy
                ></el-image>
                <!-- <img src="@/temp/1.jpg" alt="" /> -->
                <div class="item-text">
                  <div class="text-title xs line2">
                    {{ item.name }}
                  </div>
                  <make-priceformat
                    :price="item.min_price"
                    class="mr10"
                    :subscriptSize="20"
                    :firstSize="20"
                    :secondSize="20"
                  ></make-priceformat>
                </div>
              </router-link>
            </div>
          </div>
          <div class="side-item bg-white normal" v-if="!showFixed">
            <div class="item-title bold lg mb10">品牌榜</div>
            <div class="brand-list">
              <div
                class="brand-item row"
                v-for="(item, index) in goodsBrandList"
                :key="index"
              >
                <img
                  :src="require(`@/assets/icon/brand-${index + 1}.png`)"
                  alt=""
                  class="icon-brand"
                />
                <el-image
                  class="brand-logo"
                  :src="item.image"
                  fit="cover"
                  lazy
                ></el-image>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>
  </main>
</template>
<script>
import MakeStoreList from "@/components/MakeStoreList";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  name: "promake",
  props: {},
  components: {
    swiper,
    swiperSlide,
    "make-storeList": MakeStoreList,
  },
  data() {
    return {
      adId: "",
      categoryList: [],
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 10,
        loop: false, // 设置图片循环
        speed: 1000, //自动播放的速度，每隔1秒滑动一次
        autoplay: {
          //自动播放
          delay: 3000,
          disableOnInteraction: false, //用户操作swiper之后，是否禁止autoplay。默认为true：停止。
        },
        //左右导航栏
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      // options: [
      //   {
      //     value: "选项1",
      //     label: "黄金糕",
      //   },
      //   {
      //     value: "选项2",
      //     label: "双皮奶",
      //   },
      //   {
      //     value: "选项3",
      //     label: "蚵仔煎",
      //   },
      //   {
      //     value: "选项4",
      //     label: "龙须面",
      //   },
      //   {
      //     value: "选项5",
      //     label: "北京烤鸭北京烤鸭",
      //   },
      // ],
      value: "",
      listHeight: [],
      curIndex: 0,
      scrollHeight: 0,
      showFixed: false,
      type: "",
      categoryIndex: 0,
      categoryDetailList: [],
      goodsList: [],
      buyRecommendGoodsList: [],
      goodsBrandList: [],
      procurementTitle: "",
    };
  },
  computed: {},
  methods: {
    jumpCurIndex(index) {
      window.scrollTo({
        top: this.listHeight[index],
        behavior: "smooth",
      });
    },
    /**
     * @description 获取专属商城推荐列表
     */
    getBuyRecommendGoodsList() {
      this.$api
        .getBuyRecommendGoodsListApi({
          type: this.type,
        })
        .then((res) => {
          if (res.code == 1) {
            this.buyRecommendGoodsList = res.data.slice(0,6);
          }
        });
    },
    /**
     * @description 获取分类列表
     */
    getBuyCategoryList() {
      this.$api
        .getBuyCategoryListApi({
          type: this.type,
        })
        .then((res) => {
          if (res.code == 1) {
            this.categoryList = res.data;
            if (this.categoryList.length) {
              this.resetCategoryList();
            } else {
              this.categoryDetailList = [];
            }
          }
        });
    },
    /**
     * @description 重置分类列表
     */
    resetCategoryList() {
      this.categoryDetailList = this.categoryList[this.categoryIndex].sub.map(
        (el, index) => {
          this.getBuyGoodsList(el.sub[0].id, index);
          el.current = 0; 
          return el;
        }
      );
      console.log(this.categoryDetailList)
    },
    /**
     * @description 获取专属商城品牌榜单
     */
    getBuyGoodsBrandList() {
      this.$api
        .getBuyGoodsBrandListApi({
          type: this.type,
        })
        .then((res) => {
          if (res.code == 1) {
            this.goodsBrandList = res.data;
          }
        });
    },
    /**
     * @description 获取分类列表
     * @param {Number} category_id 分类ID
     * @param {Number} index 操作索引
     */
    getBuyGoodsList(category_id, index) {
      this.$api
        .getBuyGoodsListApi({
          type: this.type,
          category_id,
        })
        .then((res) => {
          if (res.code == 1) {
            this.$set(this.categoryDetailList[index], "goodsList", res.data);
          }
        });
    },
    /**
     * @description 设置分类项数据
     * @param {Number} current 当前分类
     * @param {Number} index 当前分类下子分类
     */
    setCategoryCurrent(current, index) {
      this.$set(this.categoryDetailList[current], "current", index);
      this.getBuyGoodsList(this.categoryDetailList[current].sub[index].id, current);
    },
  },
  created() {
    this.type = this.$route.query.type;
    this.adId = this.type == "gov" ? 34 : this.type == "company" ? 35 : 36;
    this.procurementTitle =
      this.type == "gov"
        ? '政府采购'
        : this.type == "company"
        ? "企业采购"
        : "场景采购";
    this.getBuyCategoryList();
    this.getBuyRecommendGoodsList();
    this.getBuyGoodsBrandList();
  },
  mounted() {
    setTimeout(() => {
      // 在下次 DOM 更新循环结束之后执行延迟回调。在修改数据之后立即使用这个方法，获取更新后的 DOM。
      this.$nextTick(() => {
        //this.listHeight是在vue实例上添加一个新属性，但并不是响应式数据
        this.listHeight = this.$refs.floor.map((item) => {
          return item.offsetTop;
        });
      });
    }, 2000);
    window.onscroll = () => {
      var tops = document.documentElement.scrollTop || document.body.scrollTop;
      for (var i = 0; i < this.listHeight.length; i++) {
        if (this.listHeight[i] <= tops + 200 && tops <= this.listHeight[i]) {
          this.curIndex = i;
        }
      }
      if (this.listHeight[2] && tops > this.listHeight[2]) {
        if (this.showFixed) return;
        this.showFixed = true;
      } else {
        this.showFixed = false;
      }
    };
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang='scss' scoped>
.main-box {
  align-items: flex-start;
}
.select-box {
  width: 100px;
  height: 32px;
  line-height: 32px;
  margin-right: 44px;
  /deep/ .el-input {
    height: inherit;
    .el-input__inner {
      height: inherit;
      border: $-solid-border;
      border-radius: 0;
      color: $-color-lighter;
      font-size: 12px;
      &:focus {
        border-color: $-color-primary;
      }
    }
    .el-input__icon {
      line-height: inherit;
    }
  }
}
.el-select-dropdown__item {
  font-size: 12px;
  &.selected {
    color: $-color-primary;
  }
}
.nav-item {
  &.white:hover {
    color: $-color-white !important;
  }
}
.floor-nav {
  height: 40px;
  .nav-item {
    padding: 0 25px;
    height: inherit;
    cursor: pointer;

    &.active {
      background-color: $-color-primary;
    }
  }
}
.floor-list {
  width: 950px;
  .floor-content {
    height: 590px;
    .content-l {
      padding: 20px 15px;
      width: 190px;
      height: inherit;
      .router-list {
        overflow: hidden;
        .router-item {
          line-height: 28px;
          white-space: nowrap;
        }
      }
    }
    .content-r {
      width: 750px;
      height: inherit;
      .img-box {
        width: 100%;
        height: 300px;
      }
      .swiper {
        .swiper-button-prev,
        .swiper-button-next {
          background-image: url("~@/assets/icon/icon-arrow3.png");
          right: 0;
          width: 29px;
          height: 56px;
          background-size: 29px 56px;
        }
        .swiper-button-prev {
          transform: rotate(180deg);
          left: 0;
        }
        .pro-item {
          width: 200px;
          height: 280px;
          padding: 23px;
          .pro-img {
            width: 153px;
            height: 153px;
            display: block;
            margin-bottom: 15px;
          }
          .pro-title {
            line-height: 20px;
          }
        }
      }
    }
  }
}
.side-list {
  width: 240px;
  .side-item {
    padding: 20px;
    // height: 590px;
    &.fixed {
      position: fixed;
      width: inherit;
      top: 0;
    }
    .pro-list {
      .pro-item {
        align-items: normal;
        margin-bottom: 12px;
        .pro-img {
          width: 80px;
          height: 80px;
          display: block;
          flex-shrink: 0;
        }
        .item-text {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 10px 0;
          .pro-title {
            line-height: 20px;
          }
        }
      }
    }
    .brand-list {
      .brand-item {
        margin-bottom: 7px;
      }
      .icon-brand {
        width: 54px;
        height: 60px;
        margin-right: 20px;
      }
      .brand-logo {
        width: 120px;
        height: 95px;
      }
    }
  }
}
</style>